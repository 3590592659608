// React Imports
import React from 'react';
// Style Import
import '../Pages/style/gallery.css'

function Gallery(){
    return(
        <section className='gallery-container'>

        </section>
    )
}

export default Gallery