import React from 'react'
import ReactDOM from 'react-dom/client'
import '../src/Style/index.css'
import App from './Pages/App'
import { BrowserRouter } from 'react-router-dom'


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>
);
